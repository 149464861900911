import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Form,
  Button,
  Space,
} from "antd";
import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";
import {
  getSessionData,
  getUserData
} from "../../utils/Helpers";
import StudentList from "./StudentList";
import { useOutletContext } from "react-router-dom";

const { Option } = Select;

const StaffStudentProfile = () => {
  const [state, setState] = useState(null);
  const [classList, setClassList] = useState([]);
  const [ctClassList, setCtClassList] = useState([]);
  const [showList, setShowList] = useState(false);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getClassList();
  }, []);

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
    setShowList(false);
  };

  const getClassList = async () => {
    setSpinner(true);
    const classRes = await postRequest("get-teacher-class-subject", {
      session_code: getSessionData().code,
      tid: getUserData().tid,
    });

    let classArr = classRes.data.response.as_class_teacher.concat(
      classRes.data.response.as_subject_teacher
    );
    let uniqueClassList = classArr.filter(
      (item, pos) => classArr.indexOf(item) === pos
    );
    setClassList(uniqueClassList);

    let ctClassArr = classRes.data.response.as_class_teacher;
    setCtClassList(ctClassArr);
    setSpinner(false);
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Students' Profile"
        pageIcon={<i className='subheader-icon fal fa-user'></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Students' Profile</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row gutter={[15]} className="mb-3">
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        name="class_section"
                        label="Class"
                        rules={[
                          {
                            required: true,
                            message: "Please select class!",
                          },
                        ]}
                      >
                        <Select placeholder="Select Class" className="form-control"
                          onChange={(value) =>
                            handleSelectChange("classSection", value)
                          }
                        >
                          {!!classList &&
                            classList.map((s) => (
                              <Option key={s} value={s}>
                                {s}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
                  <Space>
                    <Button
                      type="primary"
                      onClick={() =>
                        setShowList(true)
                      }
                      className="btn btn-primary ml-auto waves-effect waves-themed"
                    >
                      Show Student List
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showList && (
        <StudentList
          classSection={state.classSection}
          classList={ctClassList}
        />
      )}
    </div>
  );
};

export default StaffStudentProfile;
