import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Space,
} from "antd";
import { postRequest } from "../../axios";
import { getSessionData, getSchoolData, getUserData } from "../../utils/Helpers";

import { ErrorNotificationMsg, SuccessNotificationMsg, } from "../../utils/NotificationHelper";
import { useOutletContext } from "react-router-dom";

const StudentListSummative = (props) => {
  const formRef = useRef();
  const [tabulation, setTabulation] = useState(null);
  const [marksList, setMarksList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getTabulation();
  }, []);

  const getTabulation = async () => {
    setSpinner(true);
    const response = await postRequest("exam-marks-tabulation-summative-list", {
      schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      classSection: props.classSection,
      examId: props.examId,
      subjectId: props.subjectId,
      userId: getUserData().tid,
    });

    setSpinner(false);
    if (response.data.error > 0) {
      ErrorNotificationMsg(response.data.errmsg);
    } else {
      setTabulation(response.data.response.data);
      setMarksList(response.data.response.data.students);
    }
  };

  const checkMarks = (event, student) => {
    let fm = tabulation.fullMarks;

    let mo = parseInt(event.target.value);
    if (mo !== 111 && mo !== 222 && mo !== 333) {
      if (fm < mo) {
        event.target.value = 0;
        handleMarksChange(event, student);
        ErrorNotificationMsg("Marks can not be more than " + fm);
      } else {
        SaveMarks(student);
      }
    }
  };

  const handleMarksChange = (event, student) => {
    let mo = event.target.value;
    let items = [...marksList];
    let documentIndex = items.findIndex(
      (res) => res.classId === student.classId
    );
    let item = { ...items[documentIndex] };
    item.marks = mo;
    items[documentIndex] = item;

    setMarksList(items);
  }

  const SaveMarks = async (student) => {
    let marksArr = [];

    let items = [...marksList];
    let documentIndex = items.findIndex(
      (res) => res.classId === student.classId
    );

    let mInfo = items[documentIndex];
    marksArr.push({
      classId: mInfo.classId,
      marks: mInfo.marks,
    });

    const payload = {
      schoolCode: getSchoolData().school_code,
      sessionCode: getSessionData().rcode,
      classSection: props.classSection,
      examId: props.examId,
      subjectId: props.subjectId,
      userId: getUserData().tid,
      form_data: {
        marks: marksArr
      }
    };

    try {
      const res = await postRequest("exam-marks-tabulation-summative-update", payload);

      if (res.data.error === 0) {
        // SuccessNotificationMsg("Success", "Marks successfully updated.");
        // props.handleShowList(false);
      } else {
        ErrorNotificationMsg("Sorry!! Unable to save marks.");
      }
    } catch (error) {
      ErrorNotificationMsg("Something went wrong!!");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Student List of Class : {props.classSection}</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <div className="form-row">
                  <div className="col-md-12 mb-2">
                    <div className="alert alert-danger fade show px-3 py-2">
                      Please enter [ <strong>111</strong> if Absent (AB) ] & [ <strong>222</strong> if Not Applicable (NA) ]
                    </div>
                  </div>
                </div>
                <Form
                  autoComplete="off"
                  layout="vertical"
                  ref={formRef}
                >
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered table-hover">
                      <thead className="thead-themed">
                        <tr className="text-center">
                          <th>ROLL</th>
                          <th>NAME</th>
                          <th>WRITTEN [F.M.- {tabulation?.fullMarks}]</th>
                        </tr>
                      </thead>
                      <tbody>
                        {marksList &&
                          marksList.map((student) => {
                            return (
                              <tr key={student?.classId}>
                                <td>{student?.stdRoll}</td>
                                <td><strong>{student?.stdName}</strong></td>
                                <td>
                                  {tabulation?.lockTabulation === 1 ?
                                    <Input
                                      type="text"
                                      name="marks"
                                      value={student?.marks}
                                      disabled={true}
                                    />
                                    :
                                    <Input
                                      type="text"
                                      name="marks"
                                      value={student?.marks}
                                      id={student?.classId}
                                      onBlur={(event) => checkMarks(event, student)}
                                      onChange={(event) => handleMarksChange(event, student)}
                                    />
                                  }
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentListSummative;