import React, { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import Linkify from 'react-linkify';
import moment from "moment";
import { Modal, Space, Switch, Button } from "antd";

import { postRequest } from "../../axios";
import HomeWorkComment from "./HomeWorkComment";
import HomeWorkLikeList from "./HomeWorkLikeList";
import SubmittedHomeWorkDetail from "./SubmittedHomeWorkDetail";

import { ShowDocumentPreview, getUserType } from "../../utils/Helpers";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/NotificationHelper";

import userIcon from "../../images/userIcon.jpg";

const HomeWorkDetail = (props) => {
  const [showModel, setShowModel] = useState(false);
  const [homeWorkDetail, setHomeWorkDetail] = useState(null);
  const [submissionAllow, setSubmissionAllow] = useState(true);
  const setSpinner = useOutletContext();

  const hideModelFunction = () => {
    setShowModel(false);
  };

  const showModelFunction = () => {
    setShowModel(true);
    getHomeWork();
  };

  const getHomeWork = async () => {
    setSpinner(true);
    const response = await postRequest("get-single-homework", {
      hid: props.homeWorkDetail.id,
    });
    setHomeWorkDetail(response.data.response);
    setSubmissionAllow(response.data.response.is_submission_allowed);
    setSpinner(false);
  };

  const deleteHomeWork = async () => {
    setSpinner(true);
    await postRequest("remove-home-work", {
      hid: props.homeWorkDetail.id,
      status: "1",
    });
    SuccessNotificationMsg("Success", "Homework deleted successfully");

    setTimeout(() => {
      window.location.reload();
    }, 2000);
    setSpinner(false);
  };

  var classDateTime = moment(
    homeWorkDetail?.submission_date,
    "DD-MM-YYYY"
  ).format("YYYY-MM-DD");
  var isSubmissionDateOver = moment().diff(classDateTime, "day") > 0 ? 1 : 0;

  const renderButtons = () => {
    if (isSubmissionDateOver === 1) {
      if (homeWorkDetail?.is_submission_allowed === 1) {
        return (
          <Link className="btn btn-sm btn-danger" to="/submit-home-work" state={{
            hid: homeWorkDetail?.id,
            student_id: homeWorkDetail?.homework_submitted_id
          }}>
            SUBMIT HOMEWORK
          </Link>
        );
      } else {
        if (homeWorkDetail?.student_homework_status === 1) {
          return <SubmittedHomeWorkDetail homeWorkDetail={homeWorkDetail} />;
        } else {
          return (
            <span className="badge badge-danger p-2">
              Homework Submission Disabled
            </span>
          );
        }
      }
    } else if (homeWorkDetail?.is_homework_complete === 1) {
      return <SubmittedHomeWorkDetail homeWorkDetail={homeWorkDetail} />;
    } else if (homeWorkDetail?.is_homework_complete === 2) {
      return (
        <Link className="btn btn-sm btn-danger" to="/submit-home-work" state={{
          hid: homeWorkDetail?.id,
          student_id: homeWorkDetail?.homework_submitted_id
        }}>
          RE-SUBMIT HOMEWORK
        </Link>
      );
    } else {
      if (homeWorkDetail?.student_homework_status === 1) {
        return <SubmittedHomeWorkDetail homeWorkDetail={homeWorkDetail} />;
      } else {
        return (
          <Link className="btn btn-sm btn-danger" to="/submit-home-work" state={{
            hid: homeWorkDetail?.id,
            student_id: homeWorkDetail?.homework_submitted_id
          }}>
            SUBMIT HOMEWORK
          </Link>
        );
      }
    }
  };

  const onSwitchChange = async (status) => {
    setSpinner(true);
    const response = await postRequest("toggle-homework-submission-allow", {
      hid: props.homeWorkDetail.id,
      status,
    });

    setSpinner(false);
    if (response.data.errmsg === "") {
      SuccessNotificationMsg("Success", "Change done successfully");
      setSubmissionAllow(status);
    } else {
      ErrorNotificationMsg("Something went wrong");
    }
  };

  return (
    <>
      <button
        className="btn btn-sm btn-outline-info"
        onClick={() => showModelFunction()}
      >
        OPEN
      </button>

      <Modal
        title="Homework Details"
        open={showModel}
        onOk={hideModelFunction}
        okText="Close"
        okType="secondary"
        onCancel={hideModelFunction}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div className="row">
          <div className="col-md-12">
            <img
              src={homeWorkDetail?.created_by_image}
              className="profile-image rounded-circle"
              alt="publish-by"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = userIcon;
              }}
            />
            <span className="d-block">
              Assigned On : <strong> {homeWorkDetail?.assignment_date}</strong>
            </span>
            <span className="d-block">
              Submit By : <strong>{homeWorkDetail?.submission_date}</strong>
            </span>

            <span className="d-block">
              Posted By : <strong>{homeWorkDetail?.published_by}</strong>
            </span>
            {getUserType() === "staff" ? (
              homeWorkDetail?.approved ? (
                <span className="d-block mb-2">
                  Approved By : <strong>{homeWorkDetail?.approve_by}</strong>
                </span>
              ) : (
                <>
                  {homeWorkDetail?.is_draft ? (
                    <span className="d-block mb-2">
                      <span className="badge badge-warning">DRAFT</span>
                    </span>
                  ) : (
                    <span className="d-block mb-2">
                      Approved Status :{" "}
                      <span className="badge badge-danger">Pending</span>
                    </span>
                  )}

                  <Space>
                    <Link className="btn btn-sm btn-outline-info" to="/edit-home-work" state={{ hid: homeWorkDetail?.id }} >
                      Edit
                    </Link>
                    <button className="btn btn-sm btn-outline-danger ml-2" onClick={deleteHomeWork}>
                      <i className="fal fa-bin"></i> Delete
                    </button>
                  </Space>
                </>
              )
            ) : (
              ""
            )}

            <span className="d-block text-primary mt-2">
              <HomeWorkLikeList
                homeWorkDetail={homeWorkDetail}
                hideParentModel={() => hideModelFunction()}
              />

              {homeWorkDetail?.comment_enable ? (
                <HomeWorkComment hideParentModel={() => hideModelFunction()} />
              ) : (
                ""
              )}

              {getUserType() === "staff" ? homeWorkDetail?.documents_count : ""}{" "}
              <i
                className={
                  homeWorkDetail?.documents_count > 0
                    ? "fas fa-paperclip"
                    : "fal fa-paperclip"
                }
              ></i>
            </span>
          </div>
        </div>
        <hr />

        {getUserType() !== "staff" ? (
          <Space>{renderButtons()}</Space>
        ) : (
          <Space>
            <span className="d-block">
              Allow or Disallow submission{" "}
              <Switch
                checked={submissionAllow}
                onChange={onSwitchChange}
              />
            </span>

            {homeWorkDetail?.approved === 1 && (
              <span className="d-block">
                <Link className="btn btn-sm btn-success" to="/submitted-home-work" state={{ hid: homeWorkDetail?.id }} >
                  VIEW SUBMITTED HOMEWORK
                </Link>
              </span>
            )}
          </Space>
        )}

        <hr />
        <div className="row">
          <div className="col-md-12">
            {getUserType() === "staff" && (
              <span className="d-block">
                Class : <strong>{homeWorkDetail?.class_code}</strong>
              </span>
            )}
            <span className="d-block">
              Subject : <strong>{homeWorkDetail?.subject}</strong>
            </span>
            <span className="d-block">
              Page No. : <strong>{homeWorkDetail?.page_no}</strong>
            </span>
            <span className="d-block">
              Chapter No : <strong>{homeWorkDetail?.chapter_no}</strong>
            </span>
            <span className="d-block">
              Topic : <strong>{homeWorkDetail?.topic}</strong>
            </span>
            <hr />
            <span className="d-block">Description : <Linkify>{homeWorkDetail?.description}</Linkify></span>
          </div>
        </div>
        {homeWorkDetail?.documents?.length !== 0 && (
          <>
            <hr />
            <span className="badge badge-warning">Attachment(s)</span>
            <div className="row mt-3">
              {homeWorkDetail &&
                homeWorkDetail.documents &&
                homeWorkDetail.documents.map((doc, key) => {
                  return (
                    <div className="col-md-3" key={key}>
                      {ShowDocumentPreview(doc.file_url, doc.ext)}
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default HomeWorkDetail;
