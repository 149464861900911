import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Select,
  Form,
  Button,
  Space,
} from "antd";
import { postRequest } from "../../axios";

import PageHeader from "../common/PageHeader";
import {
  getSessionData,
  getUserData,
  getSchoolData
} from "../../utils/Helpers";
import { useOutletContext } from "react-router-dom";
import StudentListSummative from "./StudentListSummative";

const { Option } = Select;

const TabulationSummative = () => {
  const formRef = useRef();
  const [state, setState] = useState({
    class_section: null,
    subject_id: null,
    exam_id: null,
  });
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [showList, setShowList] = useState(false);
  const setSpinner = useOutletContext();

  useEffect(() => {
    getClassList();
  }, []);

  const getClassList = async () => {
    setSpinner(true);
    const classRes = await postRequest("get-teacher-class-subject", {
      session_code: getSessionData().code,
      tid: getUserData().tid,
    });

    let classArr = classRes.data.response.as_class_teacher.concat(
      classRes.data.response.as_subject_teacher
    );
    let uniqueClassList = classArr.filter(
      (item, pos) => classArr.indexOf(item) === pos
    );
    setClassList(uniqueClassList);
    setSpinner(false);
  };

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
    setShowList(false);
  };

  const handleShowList = (value) => {
    setShowList(value);
  }

  const handleClassChange = async (field, value) => {
    let stdClass = value.slice(0, -2);
    let stdSection = value.slice(-1);
    setState({ ...state, [field]: value, subject_id: null, exam_id: null });
    formRef.current.setFieldsValue({ subject_id: null, exam_id: null });

    setSpinner(true);
    const subjectRes = await postRequest("get-subject-by-class-multi-section", {
      session_code: getSessionData().code,
      class_code: stdClass,
      sections: [stdSection],
      tid: getUserData().tid,
    });

    setSubjectList(subjectRes.data.response);

    const examRes = await postRequest("exam-list", {
      type: "summative",
      class_code: stdClass,
    });
    setExamList(examRes.data.response);
    setSpinner(false);
  };

  return (
    <div id="content">
      <PageHeader
        pageTitle="Summative [I-VIII]"
        pageIcon={<i className="subheader-icon fal fa-clipboard-list-check"></i>}
      />
      <div className="row">
        <div className="col-md-12">
          <div id="panel-1" className="panel">
            <div className="panel-hdr">
              <h2>Summative Tabulation [I-VIII]</h2>
            </div>
            <div className="panel-container show">
              <div className="panel-content">
                <Form
                  autoComplete="off"
                  layout="vertical"
                  ref={formRef}
                >
                  <Row gutter={[15]} className="mb-3">
                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        name="class_section"
                        label="Class"
                        rules={[
                          {
                            required: true,
                            message: "Please select class!",
                          },
                        ]}
                      >
                        <Select placeholder="Select Class"
                          onChange={(value) =>
                            handleClassChange("class_section", value)
                          }
                        >
                          {!!classList &&
                            classList.map((s) => (
                              (s.slice(0, -2) !== 'IX' && s.slice(0, -2) !== 'X') && (
                                <Option key={s} value={s}>
                                  {s}
                                </Option>
                              )
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        label="Subject"
                        name="subject_id"
                        rules={[
                          {
                            required: true,
                            message: "Please select subject!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Subject"
                          onChange={(value) =>
                            handleSelectChange("subject_id", value)
                          }
                        >
                          {!!subjectList &&
                            subjectList.map((s) => (
                              <Option key={s.id} value={s.id}>
                                {s.subject_name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={8} lg={8}>
                      <Form.Item
                        name="exam_id"
                        label="Examination"
                        rules={[
                          {
                            required: true,
                            message: "Please select exam!",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          placeholder="Select Exam"
                          onChange={(value) =>
                            handleSelectChange("exam_id", value)
                          }
                        >
                          {!!examList &&
                            examList.map((el) => (
                              <Option
                                key={el.id}
                                value={el.id}
                              >
                                {el.exam}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row justify-content-end">
                  <Space>
                    <Button
                      type="primary"
                      onClick={() =>
                        setShowList(true)
                      }
                      className="btn btn-primary ml-auto waves-effect waves-themed"
                    >
                      Show Student List
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showList && (
        <StudentListSummative
          classSection={state.class_section}
          subjectId={state.subject_id}
          examId={state.exam_id}
          handleShowList={(value) => {
            handleShowList(value)
          }}
        />
      )}
    </div>
  );
};

export default TabulationSummative;