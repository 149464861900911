import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import LogoSmall from "../../styles/LogoSmall";
import { validateToken } from "../../utils/Helpers";
import { AUTH_USER_TOKEN_KEY } from "../../utils/constants";

import "antd/dist/antd.min.css";
import "../../css/page-login-alt.css";

const FrontLayout = () => {
  const [state, setState] = useState({
    loginStatus: false,
    visible: false,
  })

  // showDrawer = () => {
  //   setState({
  //     visible: true,
  //   });
  // };

  // onClose = () => {
  //   setState({
  //     visible: false,
  //   });
  // };

  useEffect(() => {
    const loginStatus = validateToken(
      localStorage.getItem(AUTH_USER_TOKEN_KEY)
    );
    setState({ loginStatus });
  }, []);

  return (
    <div className="login-page">
      <div className="blankpage-form-field">
        <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
          <a
            href="#"
            className="page-logo-link press-scale-down d-flex align-items-center"
          >
            <LogoSmall />
          </a>
        </div>

        <Outlet />
      </div>
    </div>
  );
}

export default FrontLayout;
