import React from "react";

import "antd/dist/antd.min.css";
import { Outlet } from "react-router-dom";

const MobileLayout = (props) => {
  return (
    <div className="page-wrapper mod-nav-link">
      <div className="page-inner">
        <div className="page-content-wrapper">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MobileLayout;